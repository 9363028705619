.active {
    color: #438a5e;
    text-decoration: none;
}

.blureffect {
    filter: blur(3px);

}

.active>.menuItem {
    border-bottom: 2px solid #438a5e;
}

.linkMenuItem {
    text-decoration: none;
    color: black;
}

.menuItem:hover {
    color: #438a5e;
    border-bottom: 2px solid #438a5e;
}

.menuItem {
    display: inline-block;
    padding: 1%;
    font-weight: 500;
    line-height: 3.334em !important;
    margin-left: 50px;
}

.backButton:hover {
    cursor: pointer;
    color: #438a5e;
}

.backButton {

    margin-bottom: 40px !important;
    margin-top: 15px !important;
    cursor: pointer;
    min-height: 80px;
    box-shadow: 0 1.8px 2.2px rgb(0 0 0 / 3%), 0 3.7px 1.3px rgb(0 0 0 / 5%), 0 1.5px 10px rgb(0 0 0 / 6%),
        0 22.3px 17.9px rgb(0 0 0 / 7%), 0 4.8px 33.4px rgb(0 0 0 / 9%), 0 10px 80px rgb(0 0 0 / 12%);

    border-radius: 10px;
}

.backButtonwrapper {
    padding-top: 25px;
    padding-left: 30px;
}

.backButtonICon {
    vertical-align: middle;
    cursor: pointer;
}

.verticalAlign {
    margin-top: auto;
    margin-bottom: auto;
}

.link {
    text-decoration: none;
    color: inherit;
}

.AlignCenter {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    width: fit-content;
}

.msgIcons {
    color: #438a5e;
    font-size: 1.3rem;
    float: right;
}

.actionIcons {
    color: #8b66f1;
    font-size: 1.3rem;
    float: right;
}

.tooltipMsg {
    font-size: 0.9rem;
    border-radius: 5px;
}

.inclinedImgContainer {
    border: 5px solid orange;
    transform: rotate(5deg);
    border-radius: 10px;
    width: 40vw;
}

.inclineImg {
    border-radius: 5px;
    transform: rotate(-5deg);
    width: 39vw;
}

.inclineImg:hover {
    transform: rotate(10deg);
}

.CardActions {
    max-height: fit-content;
    min-height: 120px;
}

.inclinedContainer {
    border-radius: 10px;
    width: 40vw;
    margin-top: -100px;
}

.descriptionWrapper {
    border-radius: 10px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
    margin-left: auto;
    margin-right: auto;
    background: rgba(0, 87, 142, 0.9);
    margin: 0 auto;
    max-width: 1000px !important;
    position: relative;
    text-align: center;
    padding: 15px;
}

.msgRead {
    background-color: white;
}

.sideBox {
    border-radius: 10px;

    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
}

.sideBoxWrapper {
    border-right: 2px solid rgb(153 153 255);
    margin-top: 5px;
}

.stf__item {
    border-left: 1px solid rgb(201, 201, 201);
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
}

.center {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
}

.menuItemList {
    background-color: white !important;
    border: 1px solid #f3f3f3 !important;
}

.actionSection {
    background: #f5f5f5;

    padding: 30px !important;

}

.actionSectionHeader {
    margin: 10px !important;
    margin-bottom: 20px !important;

}

.customDrop {
    margin-top: 1% !important;

}

.MuiDropzoneArea-textContainer {
    padding: 50px;
    margin-top: 10% !important;
}

a {
    text-decoration: none !important
}

.menuDropItem {
    background-color: white;
    width: 100%;
    max-width: 170px;
    height: 52px
}

.menuDropItemLink {
    text-decoration: none;
    display: flex;
}

.ticker-title {
    padding-bottom: 5px;
    font-size: 20px;
    text-transform: uppercase;
    height: 50px;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(135deg, rgb(204 194 233) 0%, rgb(204 227 229) 11%, rgb(212 247 255) 24%, rgb(230 238 241 / 93%) 30%, rgb(243 243 243 / 50%) 68%);
    margin-bottom: 50px;
}

.ticker-title>* {
    display: inline-block !important;
    margin-right: 50px !important;
    animation: title 40s infinite linear !important;
    vertical-align: middle !important;
    line-height: 3.5 !important;

}

@keyframes title {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(80vw);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#tsparticles {
    position: absolute;
    left: 0;
    margin: 10px;
    z-index: 0;
    /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
    height: 80%;
    width: 90%;
}

.PhoneInputInput {
    height: 50px;
    border-radius: 11px;
    border: 1px solid #cdcdcd;
}
.PhoneInputCountryIconImg{
    color: black;

}

.PhoneInputCountry {
    color: white;

}

.numberFormat {
    height: 50px;
    border-radius: 10px;
    border: 1px solid grey;
    padding: 10px;
    font-size: 21px
}

.react-player {
    margin-left: auto;
    margin-right: auto;
    min-height: 400px !important;
}

.react-player__preview {
    min-height: 400px !important;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.react-multi-carousel-item{
    width:fit-content!important;
    margin-left: 10px;
}
.react-multi-carousel-track{
    padding: 10px!important;
    margin-top: 50px!important;
}
.ag-chart{
    min-height: 300px;
}
#gallery > div >.ag-chart-wrapper > canvas {
    height: 1500px!important;
}